import React, { useEffect, useRef, useState } from "react"
import { api, header_token, img_url } from "../../config";

import Axios from "axios";
import LoadingMask from "react-loadingmask";
import Select from "react-select";
import { UserDetailHeader } from "../User/UserDetailHeader"
import { checkImageFlowOld } from '../../utils/Function'
import moment from "moment";
import { sort } from 'fast-sort';
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

export const GroupDetail = () => {
  const { id } = useParams();
  const { register, handleSubmit, errors, reset } = useForm();
  const { register: registerEdit, handleSubmit: handleSubmitEdit, errors: errorsEdit, reset: resetEdit, setValue: setValueEdit } = useForm();

  const [carServiceOption, setCarServiceOption] = useState([]);
  const [carService, setCarService] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [riderUnselect, setRiderUnselect] = useState([])
  const [riderReadyForAdd, setRiderReadyForAdd] = useState([])
  const [riderReadyForRemove, setRiderReadyForRemove] = useState([])
  const [riderSelected, setRiderSelected] = useState([])
  const [allRider, setAllRider] = useState([])
  const [groupList, setGroupList] = useState([])
  const [groupIdSelected, setGroupIdSelected] = useState(null)
  const [isFilter, setIsFilter] = useState(true)
  const closeAdd = useRef(null);
  const closeEdit = useRef(null);

  const onSubmitCreateGroup = async (val) => {
    setIsSubmitLoading(true)
    try {
      if (riderSelected.length === 0) {
        swal("กรุณาเพิ่มคนขับเข้ากลุ่มอย่างน้อย 1 คน", {
          icon: "warning",
        });
      } else {

        const riderListId = []
        riderSelected.map((rider) => {
          riderListId.push(rider.id)
        })
        const send_data = {
          group_name: val.group_name,
          remark: `${val.remark === "" ? '-' : val.remark}`,
          rider_id: riderListId,
          car_service: carService.value,
          user_id: id,
          status: 1,
        }

        let { status } = await Axios.post(
          `${api}/admin/rider/group`,
          send_data,
          header_token
        );


        if (status === 201) {
          closeAdd.current.click();
          swal("สร้างกลุ่มคนขับสำเร็จ", {
            icon: "success",
            timer: 1000,
          })

          await getInitData()
        }
      }
    } catch (error) {
      console.log('onSubmitCreateGroup: ', error)
      swal(`${error.response.data.message}`, {
        icon: "error",
      });
    }
    setIsSubmitLoading(false)
  }

  const onSubmitEditGroup = async (val) => {
    setIsSubmitLoading(true)
    try {
      if (riderSelected.length <= 0) {
        swal("กรุณาเพิ่มคนขับเข้ากลุ่มอย่างน้อย 1 คน", {
          icon: "warning",
        });
      } else {

        const riderListId = []
        riderSelected.map((rider) => {
          riderListId.push(rider.id)
        })

        const send_data = {
          group_name: val.group_name_edit,
          remark: `${val.remark_edit === "" ? '-' : val.remark_edit}`,
          rider_id: riderListId,
          car_service: carService.value,
          user_id: id,
          status: 1,
        }

        await Axios.put(
          `${api}/admin/rider/group/${groupIdSelected}`,
          send_data,
          header_token
        );

        closeEdit.current.click();
        swal("แก้ไขกลุ่มคนขับสำเร็จ", {
          icon: "success",
        })
        setGroupIdSelected(null)
        await getInitData()
      }
    } catch (error) {
      console.log('onSubmitEditGroup: ', error)
      swal(`${error.response.data.message}`, {
        icon: "error",
      });
    }
    setIsSubmitLoading(false)
  }

  const addToRiderGroup = async () => {
    try {
      let newFilter = riderUnselect
      for (let index = 0; index < riderReadyForAdd.length; index++) {
        const rider = riderReadyForAdd[index];
        const _filter = newFilter.filter((v) => v.id !== rider.id)
        newFilter = _filter
      }
      setRiderSelected(sort([...riderSelected, ...riderReadyForAdd]).desc(u => u.user_id))
      setRiderUnselect(newFilter)
      setRiderReadyForAdd([])
    } catch (error) {
      console.log('addToRiderGroup: ', error)
    }
  }

  const removeFormRiderGroup = async () => {
    try {
      let newFilter = riderSelected
      for (let index = 0; index < riderReadyForRemove.length; index++) {
        const rider = riderReadyForRemove[index];
        const _filter = newFilter.filter((v) => v.id !== rider.id)
        newFilter = _filter
      }
      setRiderUnselect(sort([...riderUnselect, ...riderReadyForRemove]).desc(u => u.user_id))
      setRiderSelected(newFilter)
      setRiderReadyForRemove([])
    } catch (error) {
      console.log('removeFormRiderGroup: ', error)
      swal(`${error.response.data.message}`, {
        icon: "error",
      });
    }
  }

  const getInitData = async () => {
    try {
      setIsLoading(true)
      // get all rider
      const send_data = { status: -1, role: ["rider"] };

      let { data: { users } } = await Axios.post(
        `${api}/admin/user/filter?page=${1}&limit=${9999999}`,
        send_data,
        header_token
      );

      let { data: carServiceData } = await Axios.post(
        `${api}/car-service/filter?page=${1}&limit=${99999999}`,
        { status: [1] },
        header_token
      );

      const carServiceSelect = []
      for (let index = 0; index < carServiceData.length; index++) {
        const service = carServiceData[index];
        carServiceSelect.push({
          value: service.id,
          label: service.name,
        })
      }

      if (carServiceSelect.length !== 0) {
        const _carSelected = carServiceSelect[0]
        setCarService(carServiceSelect[0])
      }

      let { data: { data } } = await Axios.get(
        `${api}/admin/rider/groups`,
        header_token
      );
      if (data.length > 0) {
        setGroupList(sort(data.filter((_group) => _group.user_id === id)).desc(d => d.created_at))
      }
      setCarServiceOption(carServiceSelect)
      setAllRider(users)
      setIsLoading(false)
    } catch (error) {
      console.log('getInitData: ', error)
      setIsLoading(false)
      swal(`เกิดข้อผิดพลาดกรุณาโหลดหน้านี้ใหม่อีกครั้ง`, {
        icon: "error",
      });
    }
  }

  const filterRider = async () => {
    try {
      const filterRider = allRider.filter((rider) => rider.services.find(_s => _s === carService.value) !== undefined)
      setRiderUnselect(filterRider)
      setRiderSelected([])
      setRiderReadyForAdd([])
      setRiderReadyForRemove([])
    } catch (error) {
      console.log('filterRider: ', error)
    }
  }

  const initDataWhenGroupSelected = async (group) => {
    setIsFilter(false)
    try {
      const op = carServiceOption.find((_op) => _op.value === group.car_service.id)
      if (op !== undefined)
        setCarService(op)

      let filterRiderEdit = [...allRider]
      for (let index = 0; index < group.rider.length; index++) {
        const rider = group.rider[index];
        filterRiderEdit = filterRiderEdit.filter(_f => {
          return _f.id !== rider._id
        })
      }
      filterRiderEdit = filterRiderEdit.filter((_rider, i) => _rider.services !== null && _rider.services.find(_s => _s === op.value) !== undefined)
      setRiderUnselect(filterRiderEdit)
      const newRiderList = [...group.rider].map(v => ({ ...v, id: v._id }))
      setRiderSelected(newRiderList)
      setValueEdit('group_name_edit', group.display_name)
      setValueEdit('remark_edit', group.remark)
      setGroupIdSelected(group._id)
    } catch (error) {
      console.log('initDataWhenGroupSelected: ', error)
      swal(`เกิดข้อผิดพลาดกรุณาโหลดหน้านี้ใหม่อีกครั้ง`, {
        icon: "error",
      });
    }
    setIsFilter(true)
  }

  const deleteGroup = async (groupId) => {
    try {
      await Axios.delete(
        `${api}/admin/rider/group/${groupId}`,
        header_token
      );
      reset()
      resetEdit()
      setGroupIdSelected(null)
      setGroupList([])
      getInitData()
      return true
    } catch (error) {
      console.log('deleteGroup: ', error)
      return false
    }
  }

  useEffect(() => {
    getInitData()
  }, [])

  useEffect(() => {
    if (carService !== null && allRider.length > 0 && isFilter) {
      filterRider()
    }
  }, [carService])

  return <div>
    <div className="dashboard-header d-flex flex-column grid-margin">
      <div className="d-flex align-items-center justify-content-between flex-wrap ">
        <div className="d-flex align-items-center">
          <h4 className="mb-0 font-weight-bold mr-2">รายละเอียด</h4>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <UserDetailHeader />
            <LoadingMask loading={isLoading} text={"loading..."}>
              <div className="tab-content" id="myTabContent" style={{ minHeight: '500px' }}>
                <div className="row mb-2">
                  <button
                    data-toggle="modal"
                    data-target=".add-rider-group"
                    className="btn btn-outline-primary btn-sm ml-auto mr-2"
                    onClick={() => {
                      setRiderUnselect(allRider.filter((rider) => rider.services !== null && rider.services.find(_s => _s === carService.value) !== undefined))
                      setRiderSelected([])
                      setRiderReadyForAdd([])
                      setRiderReadyForRemove([])
                      reset()
                    }}
                  >
                    + เพิ่มกลุ่มคนขับ
                  </button>
                </div>
                <div style={{ minHeight: 500 }}>
                  <div className="table-responsive">
                    <table id="dataTable" className="table sticky-table" style={{ marginTop: '10px' }}>
                      <thead>
                        <tr style={{ textAlign: 'center' }}>
                          <th style={{ width: 200 }}>วันที่สร้าง</th>
                          <th style={{ width: 200 }}>ชื่อ</th>
                          <th style={{ width: 200 }}>ประเภทรถ</th>
                          <th style={{ width: 100 }}>จำนวนคนขับ</th>
                          <th style={{ width: 200 }}>สถานะ</th>
                          <th style={{ width: 150 }}>ดำเนินการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupList.length > 0 && groupList.map((value, index) => {
                          return <tr
                            key={index}
                            style={{ textAlign: 'center' }}
                          >
                            <td>{moment(value.created_at).format('YYYY-MM-DD HH:mm')}</td>
                            <td>{value.display_name ?? '-'}</td>
                            <td>{value.car_service.name ?? '-'}</td>
                            <td>{value.rider ? value.rider.length : 0}</td>
                            <td>
                              {(() => {
                                switch (value.status) {
                                  case 0:
                                    return (
                                      <div className="badge badge-warning">
                                        ยกเลิก
                                      </div>
                                    );
                                  case 1:
                                    return (
                                      <div className="badge badge-success">
                                        ใช้งาน
                                      </div>
                                    );

                                  case 2:
                                    return (
                                      <div className="badge badge-secondary">
                                        ระงับ
                                      </div>
                                    );
                                  case 3:
                                    return (
                                      <div className="badge badge-danger">
                                        หมดอายุ
                                      </div>
                                    );

                                  default:
                                    break;
                                }
                              })()}
                            </td>
                            <td>
                              <button
                                data-toggle="modal"
                                data-target=".edit-rider-group"
                                className="btn btn-outline-primary mb-1 mr-1"
                                onClick={async () => {
                                  await resetEdit()
                                  await initDataWhenGroupSelected(value)
                                }}
                              >
                                แก้ไข
                              </button>
                              <button
                                className="btn btn-outline-primary mb-1"
                                onClick={() => {
                                  swal({
                                    title: `ต้องลบกลุ่มใช่หรือไม่ ?`,
                                    confirmButtonText: "ใช่",
                                    cancelButtonText: "ย้อนกลับ",
                                    icon: "warning",
                                    buttons: true,
                                    // dangerMode: true,
                                  }).then(async (willDelete) => {
                                    if (willDelete) {
                                      let is_success = await deleteGroup(
                                        value._id
                                      );
                                      if (is_success) {
                                        swal("ลบกลุ่มเสร็จสิ้น !!", {
                                          icon: "success",
                                        });
                                      } else {
                                        swal("เกิดข้อผิดพลาด !!", {
                                          icon: "error",
                                        });
                                      }
                                    }
                                  });
                                }}
                              >
                                ลบ
                              </button>
                            </td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </LoadingMask>
          </div>
        </div>
      </div>
    </div>

    <div className="modal add-rider-group" role="dialog">
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <span>เพิ่มกลุ่มคนขับ</span>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmitCreateGroup)}>
              <div className="mr-2 mb-4 ">
                {isSubmitLoading
                  ? <div style={{
                    width: '100%',
                    height: '100%',
                    alignContent: 'center',
                    textAlign: 'center'
                  }}>
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      style={{ width: '100px', height: '100px' }}
                    />
                    <div>
                      กำลังสร้างรายการใหม่...
                    </div>
                  </div>
                  : <div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: '25px' }}>
                        <label
                          htmlFor="group_name"
                          className=""
                        >
                          ชื่อกลุ่มคนขับ
                        </label>
                        <div className="" style={{ width: '70%' }}>
                          <input
                            id="group_name"
                            name="group_name"
                            type="text"
                            ref={register({
                              required: true,
                            })}
                            className={`form-control ${errors.group_name
                              ? "error-input"
                              : ""
                              }`}
                            placeholder="ชื่อกลุ่มคนขับ"
                          />
                        </div>
                      </div>
                      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: '25px' }}>
                        <label
                          htmlFor="remark"
                          className=""
                        >
                          หมายเหตุ
                        </label>
                        <div className="" style={{ width: '70%' }}>
                          <input
                            id="remark"
                            name="remark"
                            type="text"
                            ref={register({
                              required: false,
                            })}
                            className={`form-control`}
                            placeholder="หมายเหตุ"
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: '25px' }}>
                      <label
                        htmlFor="group_car_type"
                        className=""
                      >
                        ประเภทรถ
                      </label>
                      <div className="" style={{ width: '30%' }}>
                        <Select
                          id="carService"
                          name="carService"
                          options={carServiceOption}
                          placeholder={"ประเภทรถ"}
                          value={carService}
                          onChange={(e) => {
                            setCarService(e)
                          }}
                        />
                      </div>
                      <label style={{ color: 'red', fontSize: '12px', marginTop: '2px' }}>*สามารถเพิ่มคนขับได้แค่ประเภทเดียวกันเท่านั้น</label>
                    </div>
                    <div className="" style={{
                      display: 'flex',
                      flex: 1,
                      height: '450px',
                      gap: '10px',
                      marginBottom: '10px',
                    }}>
                      <div style={{ flexDirection: 'column', display: 'flex', flex: 1 }}>
                        <div
                          style={{
                            textAlign: 'center',
                            padding: '10px',
                            borderColor: 'black',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                          }}
                        >
                          คนขับที่ไม่ได้อยู่ในกลุ่ม {riderUnselect.length} คน
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            overflowY: 'scroll',
                            borderRightWidth: '1px',
                            borderRightStyle: 'solid',
                            borderLeftWidth: '1px',
                            borderLeftStyle: 'solid',
                            borderBottomWidth: '1px',
                            borderBottomStyle: 'solid',
                          }}>
                          {
                            riderUnselect.length > 0 && riderUnselect.map((item, index) => {
                              const isShowOldFlow = checkImageFlowOld(item.picture)
                              const isChecked = riderReadyForAdd.find((_f) => _f.id === item.id) === undefined ? false : true

                              return <RenderUser
                                key={`rider-unselect-${index}`}
                                index={index}
                                riderReady={riderReadyForAdd}
                                onChange={setRiderReadyForAdd}
                                riders={riderUnselect}
                                isChecked={isChecked}
                                isShowOldFlow={isShowOldFlow}
                                item={item}
                              />
                            })
                          }
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '10px' }}>
                        <button
                          className="btn btn-outline-primary"
                          type="button"
                          onClick={() => addToRiderGroup()}
                        >
                          {`เพิ่ม >`}
                        </button>
                        <button
                          className="btn btn-outline-primary"
                          type="button"
                          onClick={() => removeFormRiderGroup()}
                        >
                          {`< ลบ`}
                        </button>
                      </div>
                      <div style={{ flexDirection: 'column', display: 'flex', flex: 1 }}>
                        <div
                          style={{
                            textAlign: 'center',
                            padding: '10px',
                            borderColor: 'black',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                          }}
                        >
                          คนขับที่อยู่ในกลุ่ม {riderSelected.length} คน
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            overflowY: 'scroll',
                            borderRightWidth: '1px',
                            borderRightStyle: 'solid',
                            borderLeftWidth: '1px',
                            borderLeftStyle: 'solid',
                            borderBottomWidth: '1px',
                            borderBottomStyle: 'solid',
                          }}>
                          {
                            riderSelected.length > 0 && riderSelected.map((item, index) => {
                              const isShowOldFlow = checkImageFlowOld(item.picture)
                              const isChecked = riderReadyForRemove.find((_f) => _f.id === item.id) === undefined ? false : true

                              return <RenderUser
                                key={`rider-selected-${index}`}
                                index={index}
                                riderReady={riderReadyForRemove}
                                onChange={setRiderReadyForRemove}
                                riders={riderSelected}
                                isChecked={isChecked}
                                isShowOldFlow={isShowOldFlow}
                                item={item}
                              />
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <div className="col-12">
                  <div className="row">
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-outline-primary mr-2 ml-auto "
                      ref={closeAdd}
                      type="button"
                    >
                      ยกเลิก
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary  mr-2 "
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div >

    <div className="modal edit-rider-group" role="dialog">
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <span>เพิ่มกลุ่มคนขับ</span>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmitEdit(onSubmitEditGroup)}>
              <div className="mr-2 mb-4 ">
                {
                  isSubmitLoading
                    ? <div style={{
                      width: '100%',
                      height: '100%',
                      alignContent: 'center',
                      textAlign: 'center'
                    }}>
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        style={{ width: '100px', height: '100px' }}
                      />
                      <div>
                        กำลังสร้างรายการใหม่...
                      </div>
                    </div>
                    : <div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>

                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: '25px' }}>
                          <label
                            htmlFor="group_name"
                            className=""
                          >
                            ชื่อกลุ่มคนขับ
                          </label>
                          <div className="" style={{ width: '70%' }}>
                            <input
                              id="group_name_edit"
                              name="group_name_edit"
                              type="text"
                              ref={registerEdit({
                                required: true,
                              })}
                              className={`form-control ${errorsEdit.group_name_edit
                                ? "error-input"
                                : ""
                                }`}
                              placeholder="ชื่อกลุ่มคนขับ"
                            />
                          </div>
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: '25px' }}>
                          <label
                            htmlFor="remark"
                            className=""
                          >
                            หมายเหตุ
                          </label>
                          <div className="" style={{ width: '70%' }}>
                            <input
                              id="remark_edit"
                              name="remark_edit"
                              type="text"
                              ref={registerEdit({
                                required: false,
                              })}
                              className={`form-control`}
                              placeholder="หมายเหตุ"
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: '25px' }}>
                        <label
                          htmlFor="group_car_type"
                          className=""
                        >
                          ประเภทรถ
                        </label>
                        <div className="" style={{ width: '30%' }}>
                          <Select
                            id="carService"
                            name="carService"
                            options={carServiceOption}
                            placeholder={"ประเภทรถ"}
                            value={carService}
                            onChange={(e) => {
                              setCarService(e)
                            }}
                          />
                        </div>
                        <label style={{ color: 'red', fontSize: '12px', marginTop: '2px' }}>*สามารถเพิ่มคนขับได้แค่ประเภทเดียวกันเท่านั้น</label>
                      </div>
                      <div className="" style={{
                        display: 'flex',
                        flex: 1,
                        height: '450px',
                        gap: '10px',
                        marginBottom: '10px',
                      }}>
                        <div style={{ flexDirection: 'column', display: 'flex', flex: 1 }}>
                          <div
                            style={{
                              textAlign: 'center',
                              padding: '10px',
                              borderColor: 'black',
                              borderWidth: '1px',
                              borderStyle: 'solid',
                            }}
                          >
                            คนขับที่ไม่ได้อยู่ในกลุ่ม {riderUnselect.length} คน
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              flex: 1,
                              overflowY: 'scroll',
                              borderRightWidth: '1px',
                              borderRightStyle: 'solid',
                              borderLeftWidth: '1px',
                              borderLeftStyle: 'solid',
                              borderBottomWidth: '1px',
                              borderBottomStyle: 'solid',
                            }}>
                            {
                              riderUnselect.length > 0 && riderUnselect.map((item, index) => {
                                const isShowOldFlow = checkImageFlowOld(item.picture)
                                const isChecked = riderReadyForAdd.find((_f) => _f.id === item.id) === undefined ? false : true

                                return <RenderUser
                                  key={`rider-unselect-${item.id}`}
                                  index={index}
                                  riderReady={riderReadyForAdd}
                                  onChange={setRiderReadyForAdd}
                                  riders={riderUnselect}
                                  isChecked={isChecked}
                                  isShowOldFlow={isShowOldFlow}
                                  item={item}
                                />
                              })
                            }
                          </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '10px' }}>
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={() => addToRiderGroup()}
                          >
                            {`เพิ่ม >`}
                          </button>
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={() => removeFormRiderGroup()}
                          >
                            {`< ลบ`}
                          </button>
                        </div>
                        <div style={{ flexDirection: 'column', display: 'flex', flex: 1 }}>
                          <div
                            style={{
                              textAlign: 'center',
                              padding: '10px',
                              borderColor: 'black',
                              borderWidth: '1px',
                              borderStyle: 'solid',
                            }}
                          >
                            คนขับที่อยู่ในกลุ่ม {riderSelected.length} คน
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              flex: 1,
                              overflowY: 'scroll',
                              borderRightWidth: '1px',
                              borderRightStyle: 'solid',
                              borderLeftWidth: '1px',
                              borderLeftStyle: 'solid',
                              borderBottomWidth: '1px',
                              borderBottomStyle: 'solid',
                            }}>
                            {
                              riderSelected.length > 0 && riderSelected.map((item, index) => {
                                const isShowOldFlow = checkImageFlowOld(item.picture)
                                const isChecked = riderReadyForRemove.find((_f) => _f.id === item.id) === undefined ? false : true

                                return <RenderUser
                                  key={`rider-selected-${item.id}`}
                                  index={index}
                                  riderReady={riderReadyForRemove}
                                  onChange={setRiderReadyForRemove}
                                  riders={riderSelected}
                                  isChecked={isChecked}
                                  isShowOldFlow={isShowOldFlow}
                                  item={item}
                                />
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                }
                <div className="col-12">
                  <div className="row">
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-outline-primary mr-2 ml-auto "
                      ref={closeEdit}
                      type="button"
                      onClick={() => {
                        setRiderSelected([])
                        setRiderUnselect([])
                        setGroupIdSelected(null)
                      }}
                    >
                      ยกเลิก
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary  mr-2 "
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div >
  </div >
}

const RenderUser = ({
  item,
  riderReady = [],
  onChange = () => { },
  riders = [],
  isChecked = false,
  isShowOldFlow,
  index,
}) => {
  return <div
    onClick={() => {
      const _findIndex = riderReady.find((_f) => _f.id === item.id)
      if (_findIndex === undefined) {
        onChange([...riderReady, item])
      } else {
        const _filter = riderReady.filter((_v) => _v.id !== item.id)
        onChange([..._filter])
      }
    }}
    style={{
      padding: '10px',
      borderColor: 'black',
      borderBottomWidth: (riders.length - 1) === index ? '0.5px' : '1px',
      borderBottomStyle: 'solid',
      display: 'flex',
      gap: '10px',
      alignItems: 'center'
    }}
  >
    <input
      type="checkbox"
      checked={isChecked}
      style={{ marginRight: '5px' }}
    />
    <img
      src={
        item.picture === ""
          ? "/images/no-img.jpg"
          : isShowOldFlow ? `${img_url}${item.picture}` : item.picture
      }
      alt="profile"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = "/images/no-img.jpg";
      }}
      style={{ width: 40, height: 40, borderRadius: 40, aspectRatio: 4 / 2 }}
    />
    <span>{item.user_id}</span>
    <span style={{
      textOverflow: 'ellipsis',
      maxLines: 1,
      maxWidth: '200px',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    }}>
      {item.full_name}
    </span>
  </div>
}