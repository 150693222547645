import { Link, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { api, header_token, img_url, rider_status_option } from "../../config";

import Axios from "axios";
import LoadingMask from "react-loadingmask";
import { UserDetailHeader } from "./UserDetailHeader"
import { checkImageFlowOld } from '../../utils/Function'
import { getRoleNameText } from "../../utils/Function"
import moment from "moment";
import swal from "sweetalert";
import { useForm } from "react-hook-form";

export const UserDetail = () => {
  const { register, handleSubmit, errors } = useForm();

  const { id } = useParams();
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(null)
  const [userType, setUserType] = useState('')
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [username, setUsername] = useState('')
  const [isCompany, setIsCompany] = useState(false)
  const [listImage, setListImage] = useState([])
  const [imgShow, setImgShow] = useState('')

  const getUserDetail = async () => {
    setLoading(true)
    try {
      let { data } = await Axios.get(
        `${api}/admin/user/profile/${id}`,
        header_token
      );
      if (data !== null && data !== undefined) {
        const userTypeText = getRoleNameText(data.role.name_en)
        let roleText = userTypeText
        if (data.is_company) {
          roleText = userTypeText + `${data.user_business_type !== '' ? `(${data.user_business_type})` : ''}`
        }

        let images = []

        if (data.business_registration !== '') {
          images.push(data.business_registration)
        }

        if (data.vat_certificate !== '') {
          images.push(data.vat_certificate)
        }

        if (data.authorized_signatory_id !== '') {
          images.push(data.authorized_signatory_id)
        }

        if (data.book_bank_image !== '') {
          images.push(data.book_bank_image)
        }

        setListImage(images)
        setIsCompany(data.is_company)
        setUsername(data.username);
        setUserType(roleText)
        setData(data)
      }
    } catch (error) {
      console.log('error getUserDetail => ', error)
    }
    setLoading(false)
  }

  const onSubmit = async (data) => {
    // console.log(data);

    if (password === confirmPassword) {
      try {
        let send_data = {
          password: password,
          provider_id: "",
          provider_name: "password",
          provider_token: "",
          username: username,
        };
        let res = await Axios.put(
          `${api}/admin/user/change-password/${id}`,
          send_data,
          header_token
        );

        // console.log(res);
        if (res.status === 201 || res.status === 200) {
          swal(`เปลี่ยนรหัสผ่านเสร็จสิ้น`, {
            icon: "success",
            button: false,
          });
        }
      } catch (error) {
        swal(`${error.response.data.message}`, {
          icon: "error",
          button: false,
        });
      }
    }
  };

  useEffect(() => {
    getUserDetail()
  }, [])

  return <div>
    <div className="dashboard-header d-flex flex-column grid-margin">
      <div className="d-flex align-items-center justify-content-between flex-wrap ">
        <div className="d-flex align-items-center">
          <h4 className="mb-0 font-weight-bold mr-2">รายละเอียด</h4>
        </div>
      </div>
    </div>
    {
      data !== null
        ? <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <UserDetailHeader />
                <LoadingMask loading={loading} text={"loading..."}>
                  <div className="tab-content" id="myTabContent">
                    <div>
                      <table
                        style={{ width: "100%", tableLayout: "fixed" }}
                        border="0"
                      >
                        <tbody>
                          <div>
                            <b>ข้อมูลทั่วไป</b>
                          </div>
                          <tr>
                            <td width="20%">รหัสพนักงาน</td>
                            <td width="30%"> {data.user_id}</td>
                            <td width="20%">ประเภท</td>
                            <td width="30%">
                              {
                                data.employee_type
                                  ? data.employee_type.name_th !== ''
                                    ? data.employee_type.name_th
                                    : data.role ?
                                      data.role.name_th
                                      : data.role ?
                                        data.role.name_th
                                        : ''
                                  : ''
                              }
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">{isCompany ? 'ชื่อบริษัท' : 'ชื่อ-นามสกุล'}</td>
                            <td valign="top"> {data.full_name || '-'}</td>
                            {
                              isCompany
                                ? <>
                                  <td valign="top">อีเมล</td>
                                  <td valign="top">{data.email || '-'}</td>
                                </>
                                : <>
                                  <td valign="top">วันเดือนปีเกิด</td>
                                  <td valign="top">
                                    {data.birth_date === '' ? '-' : moment(data.birth_date).format("DD/MM/YYYY")}
                                  </td>
                                </>
                            }
                          </tr>
                          <tr>
                            <td valign="top">Company Code</td>
                            <td valign="top">{data.company_code || '-'}</td>
                            <td valign="top">Promotion Code</td>
                            <td valign="top">{data.promo_code || '-'}</td>
                          </tr>
                          <tr>
                            <td valign="top">เบอร์โทรศัพท์</td>
                            <td valign="top">{data.phone_number || '-'}</td>
                            {
                              isCompany
                                ? <>
                                  <td valign="top">ชื่อผู้ติดต่อ</td>
                                  <td valign="top">{data.emergency_contact_name || '-'}</td>
                                </>
                                : <>
                                  <td valign="top">เบอร์ผู้ติดต่อฉุกเฉิน</td>
                                  <td valign="top">{data.emergency_contact_number || '-'}</td>
                                </>
                            }
                          </tr>
                          <tr hidden={isCompany ? true : undefined}>
                            <td valign="top">ID Line</td>
                            <td valign="top">{data.line_id || '-'}</td>
                            <td valign="top">อีเมล</td>
                            <td valign="top">{data.email || '-'}</td>
                          </tr>
                          <tr>
                            <td valign="top">ที่อยู่</td>
                            <td valign="top"> {data.location.address || '-'}</td>
                            <td valign="top">ตำบล</td>
                            <td valign="top">
                              {data.location.sub_district.name_th || '-'}
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">อำเภอ</td>
                            <td valign="top">{data.location.district.name_th || '-'}</td>
                            <td valign="top">จังหวัด</td>
                            <td valign="top">{data.location.province.name_th || '-'}</td>
                          </tr>
                          <tr hidden={data.status !== 0}>
                            <td valign="top">เหตุผล</td>
                            <td valign="top">{data.ban_reason}</td>
                            <td valign="top">จำนวนวัน</td>
                            <td valign="top">
                              {data.ban_until === '' ? 'ถาวร' : `${moment(data.ban_until).fromNow()} (${moment(data.ban_until).format('YYYY-MM-DD HH:mm')})`}
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">รหัสไปรษณีย์</td>
                            <td valign="top">
                              {data.location.sub_district.zip_code || '-'}
                            </td>
                            <td valign="top">สถานะ</td>
                            <td valign="top">
                              {(() => {
                                let _status = rider_status_option.filter(
                                  (e) =>
                                    parseInt(e.value) === parseInt(data.status)
                                );
                                // console.log(_status);
                                if (_status[0]) {
                                  return `${_status[0].label}`;
                                }
                              })()}
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">สร้างเมื่อ</td>
                            <td valign="top">{moment(data.create_at).format('YYYY-MM-DD HH:mm')}</td>
                            <td valign="top">หมายเหตุ</td>
                            <td valign="top">{data.remark || '-'}</td>
                          </tr>
                          <br />
                          <tr>
                            <td>
                              <div>
                                <b>ข้อมูลบัญชี</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">วอลเล็ต</td>
                            <td valign="top">฿{data.credit_bonus !== undefined ? data.credit_bonus.toLocaleString() : 0}</td>
                            <td valign="top">เครดิต</td>
                            <td valign="top">฿{data.credit !== undefined ? data.credit.toLocaleString() : 0}</td>
                          </tr>
                          <tr>
                            <td valign="top">แต้ม</td>
                            <td valign="top">฿{data.point !== undefined ? data.point.toLocaleString() : 0}</td>
                          </tr>
                          <br />
                          <tr hidden={!isCompany}>
                            <td>
                              <div>
                                <b>เอกสาร</b>
                              </div>
                            </td>
                          </tr>
                          <tr hidden={!isCompany}>
                            <div className="col">
                              <div>
                                <input
                                  type="checkbox"
                                  checked={data.business_registration !== ''}
                                  style={{ marginRight: '5px' }}
                                  disabled
                                />
                                หนังสือรับรองบริษัท
                              </div>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={data.authorized_signatory_id !== ''}
                                  style={{ marginRight: '5px' }}
                                  disabled
                                />
                                สำเนาบัตรประชาชน
                              </div>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={data.book_bank_image !== ''}
                                  style={{ marginRight: '5px' }}
                                  disabled
                                />
                                Bookbank
                              </div>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={data.vat_certificate !== ''}
                                  style={{ marginRight: '5px' }}
                                  disabled
                                />
                                ภพ 20
                              </div>
                            </div>
                          </tr>
                          <br />
                          <tr hidden={!isCompany}>
                            <td>
                              <div>
                                <b>ข้อมูลเพิ่มเติม</b>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="row" style={{ flex: 1, marginLeft: '10px', marginTop: '10px' }}>
                        {
                          listImage.map((img, index) => {
                            return <div
                              key={`list-image-${index}`}
                              className="thumbnail cursor-img"
                              style={{ marginRight: '20px' }}
                              data-toggle="modal"
                              data-target=".show-img"
                              onClick={() => {
                                const isShowOldFlow = checkImageFlowOld(img)
                                setImgShow(
                                  img === ""
                                    ? "/images/no-img.jpg"
                                    : isShowOldFlow ? `${img_url}${img}` : img
                                );
                              }}
                            >
                              <img
                                alt=""
                                className="lazy img-responsive"
                                src={
                                  img === ""
                                    ? "/images/no-img.jpg"
                                    : img
                                }
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = "/images/no-img.jpg";
                                }}
                              />
                            </div>
                          })
                        }
                      </div>
                    </div>

                    <div className="row" style={{ justifyContent: 'end' }}>
                      <a
                        tabIndex="0"
                        data-toggle="modal"
                        data-target=".changePassword"
                        className="btn btn-primary mr-3  ml-auto"
                        hidden={data.provider_type[0] !== 'password' ? true : undefined}
                      >
                        เปลี่ยนรหัสผ่าน
                      </a>
                      <Link to={`/user/edit/${id}`} className="btn btn-primary mr-2">แก้ไข</Link>
                    </div>
                  </div>
                </LoadingMask>
              </div>
            </div>
          </div>
        </div>
        : <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h1>ไม่มีข้อมูล</h1>
              </div>
            </div>
          </div>
        </div>
    }

    <div id="showModdal" className="modal  show-img" role="dialog">
      <div className="modal-dialog ">
        <div className="modal-content" style={{ width: 800 }}>
          <div className="modal-header">
            <span>รูปภาพ</span>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body">
            <img
              alt=""
              src={imgShow ? imgShow : ""}
              style={{ maxWidth: 600, display: "block", margin: "auto" }}
            />
          </div>
        </div>
      </div>
    </div>

    <div id="showModdal" className="modal  changePassword" role="dialog">
      <div className="modal-dialog ">
        <div className="modal-content">
          <div className="modal-header">
            <span>รูปภาพ</span>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-lg-1"></div>
                <div className="col-lg-3">
                  <label htmlFor="username">Username</label>
                </div>
                <div className="col-lg-7">
                  <input
                    id="username"
                    className={`form-control ${errors.username ? "error-input" : ""
                      }`}
                    name="username"
                    type="text"
                    ref={register({ required: true, min: 6 })}
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-1"></div>
              </div>

              {password !== confirmPassword ? (
                <div className=" row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-5">
                    <label htmlFor="name" style={{ color: "red" }}>
                      รหัสผ่านไม่ตรงกัน
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="form-group row">
                <div className="col-lg-1"></div>

                <div className="col-lg-3">
                  <label htmlFor="password">Password</label>
                </div>
                <div className="col-lg-7">
                  <input
                    id="password"
                    className={`form-control ${errors.password ? "error-input" : ""
                      }`}
                    name="password"
                    type={isShowPassword ? "text" : "password"}
                    ref={register({ required: true })}
                    value={password}
                    onChange={async (e) => {
                      await setPassword(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-1"></div>
              </div>
              <div className="form-group row">
                <div className="col-lg-1"></div>

                <div className="col-lg-3">
                  <label htmlFor="confirm_password">Confirm password</label>
                </div>
                <div className="col-lg-7">
                  <input
                    id="confirm_password"
                    className={`form-control ${errors.confirm_password ? "error-input" : ""
                      }`}
                    name="confirm_password"
                    type={isShowPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={async (e) => {
                      await setConfirmPassword(e.target.value);
                    }}
                  />
                  <div className="form-check mt-4 ml-4">
                    <input
                      type="checkbox"
                      className="form-check-input "
                      checked={isShowPassword}
                      onChange={() => setIsShowPassword(!isShowPassword)}
                    />
                    Show Password
                  </div>
                </div>
                <div className="col-lg-1"></div>
              </div>
            </div>

            <div className="row mr-2 mb-4">
              <div className="col-12">
                <div className="row">
                  <a
                    tabIndex="0"
                    data-dismiss="modal"
                    aria-label="Close"
                    className="btn btn-outline-primary mr-2 ml-auto "
                  >
                    ยกเลิก
                  </a>
                  <button
                    className="btn btn-primary  mr-2 "
                    type="submit"
                  >
                    เปลี่ยนรหัสผ่าน
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
}