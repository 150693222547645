import React, { useEffect, useRef } from "react";

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportOrderCarData = ({ data, trigger, settrigger }) => {
  const exportButton = useRef();

  useEffect(() => {
    if (trigger) {
      exportButton.current.click();
      settrigger(false);
    }
  }, [trigger]);

  return (
    <ExcelFile
      filename={`Order_Car_export`}
      element={
        <button
          type="submit"
          className="btn btn-primary mr-2"
          style={{ marginTop: 23, display: "none" }}
          ref={exportButton}
        >
          Download Data
        </button>
      }
    >
      <ExcelSheet data={data} name="Order Car Service">
        <ExcelColumn label="Order No" value="order_no" />
        <ExcelColumn label="ประภทงาน" value="service_type" />
        <ExcelColumn label="วันที่เรียกงาน" value="order_time_create" />
        <ExcelColumn label="วันที่จัดส่ง" value="order_time_start" />
        <ExcelColumn label="วันที่จัดส่งสำเร็จ" value="order_time_end" />
        <ExcelColumn label="ต้นทาง" value="address" />
        <ExcelColumn label="ปลายทาง" value="destinations" />
        <ExcelColumn label="รหัสบริษัท" value="business_code" />
        <ExcelColumn label="ข้อมูลลูกค้า" value="user" />
        <ExcelColumn label="ประเภทรถ" value="car_service_name" />
        <ExcelColumn label="ผู้รับงาน" value="rider" />
        <ExcelColumn label="ระยะทาง" value="distance" />
        <ExcelColumn label="ค่าขนส่ง" value="car_service_cost" />
        <ExcelColumn label="ค่าบริการเสริม" value="car_service_addon_cost" />
        <ExcelColumn label="รหัสโปรโมชั่น" value="promotion_code" />
        <ExcelColumn label="รวม" value="total" />
        <ExcelColumn label="ประเภทการชำระ" value="payment_method" />
        <ExcelColumn label="ผู้ชำระ" value="paid_by" />
        <ExcelColumn label="สถานะ" value="status" />
        <ExcelColumn label="หมายเหตุ" value="remark" />
      </ExcelSheet>
    </ExcelFile>
  );
};
