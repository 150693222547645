import "react-loadingmask/dist/react-loadingmask.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import { api, header_token } from "../../config";

import Axios from "axios";
import LoadingMask from "react-loadingmask";
import { NotiContext } from "../../store/keep";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { convertPaidByText } from "../../utils/Function"
import moment from "moment";
import swal from "sweetalert";

const limitOptions = [
  { label: "50", value: 50, },
  { label: "100", value: 100, },
  { label: "150", value: 150, },
]

export const OrderCarTable = (props) => {

  const {
    loading = false,
    data = [],
    setLimit = () => { },
    limit = { label: "50", value: 50 },
    page_count = 0,
    setcurrent_page = () => { },
    current_page = 1,
  } = props

  return <>
    <LoadingMask loading={loading} text={"loading..."}>
      <div style={{ minHeight: 500 }}>
        <div className="sticky-div">
          <table id="dataTable" className="table sticky-table">
            <thead>
              <tr style={{ textAlign: 'center' }}>
                <th style={{ width: 150 }} className="sticky-head">
                  Order No.
                </th>
                <th style={{ width: 200 }}>วันที่เรียกงาน</th>
                <th style={{ width: 200 }}>วันที่จัดส่ง</th>
                <th style={{ width: 300 }}>ที่อยู่</th>
                <th style={{ width: 150 }}>รหัสบริษัท</th>
                <th style={{ width: 200 }}>ข้อมูลลูกค้า</th>
                <th style={{ width: 150 }}>ประเภทรถ</th>
                <th style={{ width: 200 }}>ชื่อคนขับ</th>
                <th style={{ width: 150 }}>ระยะทาง</th>
                <th style={{ width: 150 }}>ค่าขนส่ง</th>
                <th style={{ width: 150 }}>ค่าบริการเสริม</th>
                <th style={{ width: 200 }}>รหัสโปรโมชั่น</th>
                <th style={{ width: 150 }}>รวม</th>
                <th style={{ width: 150 }}>ประเภทกาชำระ</th>
                <th style={{ width: 150 }}>ผู้ชำระ</th>
                <th style={{ width: 150 }}>สถานะ</th>
                <th style={{ width: 250 }}>หมายเหตุ</th>
              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => {
                return (
                  <tr
                    key={index}
                    style={{ textAlign: 'center' }}
                  >
                    <td className="sticky-col">
                      <a
                        href={`/car_service_order/detail/${value.id}`}
                        tabIndex={0}
                        style={{ color: 'blue' }}
                      >
                        {value.order_no}
                      </a>
                      <br />
                      {value.type === 0 ? (
                        <span className="badge badge-outline-warning badge-pill">
                          ล่วงหน้า
                        </span>
                      ) : (
                        <span className="badge badge-outline-success badge-pill">
                          สังทันที
                        </span>
                      )}
                      {value.rider !== null
                        && value.rider !== undefined
                        && value.rider.id !== ''
                        && value.status === 1
                        && <span className="badge badge-outline-info badge-pill">
                          รอคนขับกดรับงาน
                        </span>
                      }
                    </td>
                    <td>
                      {moment(value.created_at).format('YYYY-MM-DD')}
                      <br />
                      {moment(value.created_at).format('HH:mm')}
                    </td>
                    <td>
                      {
                        value.type === 0
                          ? <>
                            {moment(value.due_date).format('YYYY-MM-DD')}
                            <br />
                            {moment(value.due_date).format('HH:mm')}
                          </>
                          : <>
                            {
                              value.shipping_date !== null && value.shipping_date !== undefined && value.shipping_date !== ''
                                ? <>
                                  {moment(value.shipping_date).format('YYYY-MM-DD')}
                                  <br />
                                  {moment(value.shipping_date).format('HH:mm')}
                                </>
                                : '-'
                            }
                          </>
                      }
                    </td>
                    <td>
                      <div
                        style={{
                          maxLines: 1,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        ต้นทาง: {
                          value.car_service_start_point.address === null || value.car_service_start_point.address === ''
                            ? `${value.car_service_start_point.sub_district.name_th} ${value.car_service_start_point.district.name_th} ${value.car_service_start_point.province.name_th} ${value.car_service_start_point.sub_district.zip_code}`
                            : value.car_service_start_point.address
                        }
                      </div>
                      {
                        value.car_service_destinations.length > 0
                          ? <>
                            <div
                              style={{
                                maxLines: 1,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              ปลายทาง: {value.car_service_destinations[0].address}
                            </div>
                            <a
                              hidden={value.car_service_destinations.length > 1 ? undefined : true}
                              href={`/car_service_order/detail/${value.id}`}
                              tabIndex={0}
                              style={{ color: 'blue' }}
                            >
                              เพิ่มเติม
                            </a>
                          </>
                          : '-'
                      }
                    </td>
                    <td>
                      {value.company_code ?? '-'}
                      <br />
                      {
                        value.user !== null && value.user.emergency_contact_name !== undefined
                          && value.user.emergency_contact_name !== null
                          ? `${value.user.emergency_contact_name ?? ''}`
                          : ''
                      }
                    </td>
                    <td>
                      {
                        value.user !== null && value.user.full_name
                          ? value.user.full_name.length > 20
                            ? `user id: ${value.user.user_id}`
                            : value.user.full_name
                          : '-'
                      }
                      <br />
                      {value.user !== null && value.user.phone_number ? value.user.phone_number : ''}
                    </td>
                    <td>
                      {
                        `${value.car_service_name ?? ''}`
                      }
                      <br />
                      {
                        value.rider !== null && value.rider !== undefined
                          ? value.rider.license_plate ?? ''
                          : ''
                      }
                    </td>
                    <td>
                      {value.rider !== null && value.rider !== undefined ? value.rider.full_name : '-'}
                      <br />
                      {value.rider !== null && value.rider !== undefined ? value.rider.phone_number : ''}
                    </td>
                    <td>~ {value.distance}km</td>
                    <td>฿{value.car_service_cost.toLocaleString()}</td>
                    <td>฿{value.car_service_addon_cost.toLocaleString()}</td>
                    <td>
                      {value.promotion_discount > 0 ? (
                        <span>
                          {value.promotion_code
                            ? value.promotion_code
                            : ""}{" "}
                          {value.promotion_discount
                            ? `(฿${value.promotion_discount.toLocaleString()})`
                            : "0"}
                        </span>
                      ) : null}
                    </td>
                    <td>฿{value.total.toLocaleString()}</td>
                    <td>{value.payment_method}</td>
                    <td>{convertPaidByText(value.paid_by)}</td>
                    <td>
                      {(() => {
                        if (value.type === 0) return "ออเดอร์ล่วงหน้า";

                        switch (value.status) {
                          case 0:
                            return "ยกเลิก";
                          case 1:
                            return "ออเดอร์ใหม่";
                          case 2:
                            return "พนักงานรับออเดอร์";
                          case 3:
                            return "ดำเนินการ";
                          case 4:
                            return "กำลังจัดส่ง";
                          case 5:
                            return "ส่งสำเร็จ";
                          case 6:
                            return "ส่งไม่สำเร็จ";

                          default:
                            break;
                        }
                      })()}
                    </td>
                    <td>{value.remark}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </LoadingMask>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <div></div>
      <div className="mt-2" >
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          breakLabel={<span className="gap">...</span>}
          pageCount={page_count}
          onPageChange={(e) => setcurrent_page(e.selected + 1)}
          forcePage={current_page - 1}
          containerClassName={"pagination"}
          previousLinkClassName={"previous_page"}
          nextLinkClassName={"next_page"}
          disabledClassName={"disabled"}
          activeClassName={"active"}
        />
      </div>
      <div className="mt-2 " style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '5px' }}>
          <span style={{ fontSize: '14px', marginRight: '10px' }}>ค้นหา:</span>
          <input
            autoComplete="off"
            type="number"
            className="form-control- global_filter"
            placeholder="1"
            style={{ width: '80px', paddingLeft: '5px', paddingRight: '5px' }}
            onChange={(e) => {
              setcurrent_page(e.target.value)
            }}
            defaultValue={1}
            min={1}
            max={page_count}
          />
        </div>
        <div style={{ minWidth: '100px' }}>
          <Select
            name="per_page"
            options={limitOptions}
            defaultValue={limit}
            onChange={async (e) => {
              setLimit(e)
            }}
            menuPlacement={'top'}
          />
        </div>
      </div>
    </div>
  </>
}