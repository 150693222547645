import "react-loadingmask/dist/react-loadingmask.css";

import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import React, { useContext, useEffect, useRef, useState } from "react";
import { api, header_token } from "../../config";

import Axios from "axios";
import LoadingMask from "react-loadingmask";
import { NotiContext } from "../../store/keep";
import { OrderCarTable } from "./OrderCarTable"
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { convertPaidByText } from "../../utils/Function"
import moment from "moment";
import swal from "sweetalert";

export const OrderCarNew = (props) => {
  const { start_date, optionArea, statusOrder, end_date } = props;
  const { noti } = useContext(NotiContext);

  const checkAllRef = useRef(null);
  const [loading, setloading] = useState(false);
  const [riderloading, setriderloading] = useState(false);
  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit, setLimit] = useState({ label: "50", value: 50, });
  const [is_first, setis_first] = useState(true);
  const [total_count, settotal_count] = useState(0);

  const [id, setid] = useState("");
  const [filter_area, setfilter_area] = useState("");
  const [filter_order, setfilter_order] = useState("");
  const [filter_name, setfilter_name] = useState("");

  const [select_order, setselect_order] = useState("");
  const [select_rider, setselect_rider] = useState("");
  const [order_option, setorder_option] = useState([]);
  const [rider_option, setrider_option] = useState([]);
  const [sort, setSort] = useState(-1);

  const limitOptions = [
    { label: "50", value: 50, },
    { label: "100", value: 100, },
    { label: "150", value: 150, },
  ]

  const assignOrder = async (rider_id, order_id) => {
    try {
      let send_data = {
        order_id: order_id,
        rider_id: rider_id,
      };

      let res = await Axios.post(
        `${api}/admin/order/assign/`,
        send_data,
        header_token
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const cancelOrder = async (order_id) => {
    try {
      let res = await Axios.get(
        `${api}/admin/order/${order_id}/cancel`,
        header_token
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getDataOnlineRider = async () => {
    setriderloading(true);
    try {
      let send_data = {
        status: 1,
        // team_id: filter_team.value,
        role: ["rider"],
        available: "online",
      };

      let { data } = await Axios.post(
        `${api}/admin/user/filter?page=${1}&limit=${9999999}`,
        send_data,
        header_token
      );
      // console.log(data);
      let tmp = [];
      data.users.map((val) => {
        tmp.push({ value: val.id, label: `${val.user_id} ${val.full_name}` });
        return;
      });

      setrider_option(tmp);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setriderloading(false);
  };

  const getData = async () => {
    setloading(true);
    try {
      let start = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(end_date, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        start_date: start,
        end_date: end,
        search: id ? id : "",
        area_id: filter_area ? filter_area.value : "",
        service: ["car"],
        status: statusOrder,
      };

      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${current_page}&limit=${limit.value}&sort=${sort}&sort_by=${"created_at"}`,
        send_data,
        header_token
      );

      if (data.orders !== null) {
        // console.log("data", data);

        let cal_page_count = Math.ceil(data.meta.total_count / limit.value);
        setpage_count(cal_page_count);
        settotal_count(data.meta.total_count);
        setdata(data.orders);
        // settotal_count(
        //   data.meta.total_count -
        //   data.orders.filter((e) => e.rider_id === "").length
        // );
        // setdata(data.orders.filter((e) => e.rider_id === ""));
      } else {
        setdata([]);
        settotal_count(0);
      }
      setis_first(false);
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
    setloading(false);
  };

  useEffect(() => {
    if (is_first) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (!is_first) {
      getData();
    }
  }, [start_date, end_date]);

  useEffect(() => {
    if (!is_first) {
      setcurrent_page(1);
      getData();
    }
  }, [filter_area, id, filter_name, limit]);

  useEffect(() => {
    if (!is_first) {
      getData();
    }
  }, [current_page]);

  useEffect(() => {
    if (noti.getdata === "car_service") {
      getData();
    }
  }, [noti]);

  useEffect(() => {
    getData();
  }, [sort])

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h4 className="mb-4 mt-2">ทั้งหมด {total_count} รายการ</h4>
              </div>

              <div className="form-row align-items-center mb-2 mr-2 ">
                <div className=" mr-2" style={{ width: 300 }}>
                  <span className="tx-13 text-muted">ค้นหา</span>
                  <input
                    type="text"
                    className="form-control- global_filter"
                    id="global_filter"
                    placeholder="Order ID / เบอร์โทร / ชื่อ / หมายเหตุ"
                    value={id}
                    onChange={(e) => setid(e.target.value)}
                  />
                </div>

                <div className="mr-1" style={{ width: 150 }}>
                  <span className="tx-13 text-muted">พื้นที่</span>
                  <Select
                    value={filter_area}
                    onChange={(e) => {
                      setfilter_area(e);
                    }}
                    options={optionArea}
                    placeholder={"พื้นที่.."}
                  />
                </div>
              </div>
            </div>
            <OrderCarTable
              loading={false}
              data={data}
              limit={limit}
              setLimit={setLimit}
              page_count={page_count}
              setcurrent_page={setcurrent_page}
              current_page={current_page}
            />
          </div>
        </div>
      </div>
      <div id="showModdal" className="modal  assign-order" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>มอบหมายงาน</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-lg-3 pt-2">
                  <label htmlFor="add_name">คำสั่งซื้อ Order No.</label>
                </div>
                <div className="col-lg-8">
                  <Select
                    value={select_order}
                    onChange={async (e) => {
                      setselect_order(e);
                    }}
                    options={order_option}
                    placeholder={"เลือก..."}
                    isMulti
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3 pt-2">
                  <label htmlFor="add_name">พนักงานขับ</label>
                </div>

                <div className="col-lg-8">
                  <LoadingMask loading={riderloading} text={"loading..."}>
                    <Select
                      value={select_rider}
                      onChange={async (e) => {
                        setselect_rider(e);
                      }}
                      options={rider_option}
                      placeholder={"เลือก..."}
                    />
                  </LoadingMask>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-11">
                  <div className="row">
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-outline-primary mr-2 ml-auto "
                    >
                      ยกเลิก
                    </button>
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-primary  mr-2 "
                      onClick={async () => {
                        if (select_order && select_rider) {
                          let send = [];
                          select_order.forEach((value) => {
                            send.push(
                              assignOrder(select_rider.value, value.value)
                            );
                          });

                          let is_success = true;
                          await Promise.all(send).then((res) => {
                            if (
                              res.filter((e) => e === true).length === 0 ||
                              res.length === 0
                            )
                              is_success = false;
                          });

                          if (is_success) {
                            props.refresh();
                            setselect_order("");
                            setselect_rider("");
                            swal("หมอบหมายงานเสร็จสิ้น !!", {
                              icon: "success",
                            });
                          } else {
                            swal("เกิดข้อผิดพลาด !!", {
                              icon: "error",
                            });
                          }
                        }
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
