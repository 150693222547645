import React, { useEffect, useRef, useState } from "react";
import { api, header_token } from "../../config";

import Axios from "axios";
import { OrderCarTable } from './OrderCarTable'
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { convertPaidByText } from "../../utils/Function"
import moment from "moment";
import swal from "sweetalert";

const option_order = [{ value: "cancel", label: "ยกเลิก" }];

export const OrderCarPreOrder = (props) => {
  const checkAllRef = useRef(null);

  const [default_data, setdefault_data] = useState([]);
  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit, setLimit] = useState({ label: "50", value: 50, });

  const [id, setid] = useState("");
  const [option_area, setoption_area] = useState([]);
  const [filter_area, setfilter_area] = useState("");
  const [filter_order, setfilter_order] = useState("");
  const [filter_name, setfilter_name] = useState("");

  const limitOptions = [
    { label: "50", value: 50, },
    { label: "100", value: 100, },
    { label: "150", value: 150, },
  ]

  const cancelOrder = async (order_id) => {
    try {
      let res = await Axios.get(
        `${api}/admin/order/${order_id}/cancel`,
        header_token
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    if (props.data) {
      setdata(props.data);
      setdefault_data(props.data);
      setoption_area(props.optionArea);
      let cal_page_count = Math.ceil(props.data.length / limit.value);
      setpage_count(cal_page_count);
    }
  }, [props]);

  useEffect(() => {
    if (default_data.length > 0) {
      let tmp = default_data;

      if (id) {
        tmp = tmp.filter((v) => v.order_no.includes(id));
      }

      setdata(tmp);
      let cal_page_count = Math.ceil(tmp.length / limit.value);
      setpage_count(cal_page_count);
    }
  }, [filter_area, id, filter_name, limit]);

  return (
    <div>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">ทั้งหมด {data.length} รายการ</h4>
                </div>

                <div className="form-row align-items-center mb-2 mr-2 ">
                  <div className=" mr-2" style={{ width: 200 }}>
                    <span className="tx-13 text-muted">Order Id</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      id="global_filter"
                      placeholder="ID"
                      value={id}
                      onChange={(e) => setid(e.target.value)}
                    />
                  </div>

                  <div className="mr-1" style={{ width: 150 }}>
                    <span className="tx-13 text-muted">พื้นที่</span>
                    <Select
                      value={filter_area}
                      onChange={async (e) => {
                        await setfilter_area(e);
                        let tmp = [];

                        setdata(tmp);

                        let cal_page_count = Math.ceil(tmp.length / limit.value);
                        setpage_count(cal_page_count);
                      }}
                      options={option_area}
                      placeholder={"พื้นที่.."}
                    />
                  </div>
                </div>
              </div>
              <OrderCarTable
                loading={false}
                data={data}
                limit={limit}
                setLimit={setLimit}
                page_count={page_count}
                setcurrent_page={setcurrent_page}
                current_page={current_page}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
