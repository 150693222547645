import { NotiContext, RulesContext } from "./store/keep";
import React, { useState } from "react";
import { Redirect, Switch } from "react-router-dom";

import { AdminProfile } from "./Components/AdminProfile";
import { CarServiceAdd } from "./Components/CarService/CarServiceAdd";
import { CarServiceEdit } from "./Components/CarService/CarServiceEdit";
import { CarServiceIndex } from "./Components/CarService/CarServiceIndex";
import { CreditCode } from "./Components/Credit/CreditCode"
import { CreditHistory } from "./Components/Credit/CreditHistory";
import { CreditIndex } from "./Components/Credit/CreditIndex";
import { Dashboard } from "./Components/Dashboard";
import { GroupDetail } from "./Components/Group/GroupDetail"
import { Maps } from "./Components/Maps/Maps"
import { NewsAdd } from "./Components/News/NewsAdd";
import { NewsEdit } from "./Components/News/NewsEdit";
import { NewsIndex } from "./Components/News/NewsIndex";
import { NotFound } from "./NotFound";
import { NotificationFirebase } from "./store/NotificationFirebase";
import { OrderCarDetail } from "./Components/OrderCarService/OrderCarDetail";
import { OrderCarIndex } from "./Components/OrderCarService/OrderCarIndex";
import PrivateRoute from "./PrivateRoute";
import { PromotionCodeHead } from "./Components/PromotionCode/PromotionCodeHead";
import { ReadNews } from "./ReadNews";
import { RiderAdd } from "./Components/Rider/RiderAdd";
import { RiderAll } from "./Components/Rider/RiderAll";
import { RiderApprove } from "./Components/Rider/RiderApprove";
import { RiderDetailCredit } from "./Components/Rider/RiderDetailCredit";
import { RiderDetailHistory } from "./Components/Rider/RiderDetailHistory";
import { RiderDetailService } from "./Components/Rider/RiderDetailService";
import { RiderDetailTransaction } from "./Components/Rider/RiderDetailTransaction"
import { RiderDetailWithdraw } from "./Components/Rider/RiderDetailWithdraw";
import { RiderEdit } from "./Components/Rider/RiderEdit";
import { RiderImport } from "./Components/Rider/RiderImport";
import { RiderTeam } from "./Components/Rider/RiderTeam";
import { RiderVehicle } from "./Components/Rider/RiderVehicle";
import { SaleBillShippingIndex } from "./Components/SaleBill/SaleBillShippingIndex";
import { SettingIndex } from "./Components/Setting/SettingIndex";
import { Sidebar } from "./Components/Sidebar";
import { Topbar } from "./Components/Topbar";
import { UserAdd } from "./Components/User/UserAdd";
import { UserAll } from "./Components/User/UserAll";
import { UserBusiness } from "./Components/User/UserBusiness";
import { UserDetail } from "./Components/User/UserDetail";
import { UserDetailTransaction } from "./Components/User/UserDetailTransaction"
import { UserEdit } from "./Components/User/UserEdit";
import { UserImport } from "./Components/User/UserImport";
import { UserNormal } from "./Components/User/UserNormal";
import { UserOrderHistory } from "./Components/User/UserOrderHistory";
import { UserWalletHistory } from "./Components/User/UserWalletHistory";
import { WithdrawHead } from "./Components/Withdraw/WithdrawHead";

export const Admin = ({ token: token }) => {
  const [rules, setrules] = useState({
    banner: false,
    deposit: false,
    member: false,
    news: false,
    order: false,
    promotion: false,
    recommend: false,
    rider: false,
    setting: false,
    shipping: false,
    summary: false,
    withdraw: false,
    branch: false,
    car_service: false,
    car_service_order: false,
    maps: false,
  });

  const [noti, setnoti] = useState({
    rider: 0,
    order: 0,
    withdraw: 0,
    credit: 0,
    getdata: "",
  });

  return (
    <RulesContext.Provider value={{ rules, setrules }}>
      <NotiContext.Provider value={{ noti, setnoti }}>
        <div className="container-scroller">
          <Topbar />
          <div className="container-fluid page-body-wrapper">
            <Sidebar />
            <div className="main-panel">
              <div className="content-wrapper">
                <NotificationFirebase />

                <Switch>
                  <PrivateRoute
                    token={token}
                    path={`/news/read/:id`}
                    component={ReadNews}
                    isAllowed={true}
                  />

                  {/* -------------------------------------- User -------------------------------------- */}
                  <PrivateRoute
                    token={token}
                    path={`/user/wallet/:id`}
                    component={UserDetailTransaction}
                    isAllowed={rules.member}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/user/order/:id`}
                    component={UserOrderHistory}
                    isAllowed={rules.member}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/user/group/rider/:id`}
                    component={GroupDetail}
                    isAllowed={rules.member}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/user/edit/:id`}
                    component={UserEdit}
                    isAllowed={rules.member}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path="/user/add"
                    component={UserAdd}
                    isAllowed={rules.member}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path="/user/business"
                    component={UserBusiness}
                    isAllowed={rules.member}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path="/user/normal"
                    component={UserNormal}
                    isAllowed={rules.member}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path="/user/detail/:id"
                    component={UserDetail}
                    isAllowed={rules.member}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path="/user"
                    component={UserAll}
                    isAllowed={rules.member}
                  />

                  <PrivateRoute
                    token={token}
                    exact
                    path="/user/import"
                    component={UserImport}
                    isAllowed={rules.member}
                  />

                  {/* -------------------------------------- Car Service --------------------------------------  */}

                  <PrivateRoute
                    token={token}
                    exact
                    path="/car_service"
                    component={CarServiceIndex}
                    isAllowed={rules.car_service_setting}
                  />

                  <PrivateRoute
                    token={token}
                    exact
                    path="/car_service/add"
                    component={CarServiceAdd}
                    isAllowed={rules.car_service_setting}
                  />

                  <PrivateRoute
                    token={token}
                    exact
                    path="/car_service/edit/:service_id"
                    component={CarServiceEdit}
                    isAllowed={rules.car_service_setting}
                  />

                  {/* -------------------------------------- Rider --------------------------------------  */}
                  <PrivateRoute
                    token={token}
                    path={`/rider/import`}
                    component={RiderImport}
                    isAllowed={rules.rider}
                  />

                  <PrivateRoute
                    token={token}
                    path={`/rider/edit/:id`}
                    component={RiderEdit}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/history/:id`}
                    component={RiderDetailHistory}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/transaction/:id`}
                    component={RiderDetailTransaction}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/vehicle/:id`}
                    component={RiderVehicle}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/service/:id`}
                    component={RiderDetailService}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/withdraw/:id`}
                    component={RiderDetailWithdraw}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/credit/:id`}
                    component={RiderDetailCredit}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path={`/rider/team`}
                    component={RiderTeam}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path={`/rider/approve`}
                    component={RiderApprove}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path={`/rider/add`}
                    component={RiderAdd}
                    isAllowed={rules.rider}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path={`/rider`}
                    component={RiderAll}
                    isAllowed={rules.rider}
                  />

                  {/* -------------------------------------- Car Service Order -------------------------------------- */}
                  <PrivateRoute
                    token={token}
                    exact
                    path="/car_service_order/detail/:id"
                    component={OrderCarDetail}
                    isAllowed={rules.car_service}
                  />

                  <PrivateRoute
                    token={token}
                    path="/car_service_order"
                    component={OrderCarIndex}
                    isAllowed={rules.car_service}
                  />

                  {/* -------------------------------------- Credit -------------------------------------- */}
                  <PrivateRoute
                    token={token}
                    exact
                    path="/credit/history"
                    component={CreditHistory}
                    isAllowed={rules.deposit}
                  />
                  {/* <PrivateRoute
                    token={token}
                    exact
                    path="/credit/promotion_code"
                    component={CreditCode}
                    isAllowed={rules.deposit}
                  /> */}
                  <PrivateRoute
                    token={token}
                    exact
                    path="/credit"
                    component={CreditIndex}
                    isAllowed={rules.deposit}
                  />
                  {/* -------------------------------------- Withdraw  -------------------------------------- */}

                  <PrivateRoute
                    token={token}
                    path="/withdraw"
                    component={WithdrawHead}
                    isAllowed={rules.withdraw}
                  />

                  {/* --------------------------------------  News  -------------------------------------- */}

                  <PrivateRoute
                    token={token}
                    exact
                    path="/news"
                    component={NewsIndex}
                    isAllowed={rules.news}
                  />

                  <PrivateRoute
                    token={token}
                    exact
                    path="/news/add"
                    component={NewsAdd}
                    isAllowed={rules.news}
                  />

                  <PrivateRoute
                    token={token}
                    exact
                    path="/news/:id"
                    component={NewsEdit}
                    isAllowed={rules.news}
                  />

                  {/* -------------------------------------- Promotion Code --------------------------------------  */}

                  <PrivateRoute
                    token={token}
                    path="/promotion_code"
                    component={PromotionCodeHead}
                    isAllowed={rules.promotion_code}
                  />

                  {/* --------------------------------------  Maps  -------------------------------------- */}

                  <PrivateRoute
                    token={token}
                    path="/maps"
                    component={Maps}
                    isAllowed={rules.rider}
                  />

                  {/* --------------------------------------  Others  -------------------------------------- */}

                  <PrivateRoute
                    token={token}
                    path="/setting"
                    component={SettingIndex}
                    isAllowed={rules.setting}
                  />
                  <PrivateRoute
                    token={token}
                    path="/sale_bill"
                    exact
                    component={SaleBillShippingIndex}
                    isAllowed={rules.summary}
                  />

                  <PrivateRoute
                    token={token}
                    path="/admin_profile"
                    component={AdminProfile}
                    isAllowed={true}
                  />

                  <PrivateRoute
                    token={token}
                    exact
                    path="/"
                    component={Dashboard}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path="*"
                    component={NotFound}
                    isAllowed={true}
                  />
                  <Redirect to="/404" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </NotiContext.Provider>
    </RulesContext.Provider>
  );
};
